import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Rewrite this
// import { Privacy_notice, Cookie_preferences, Terms_of_Service} from './components/footer';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
      <App/>
    </React.StrictMode>
);
